<template>
	<v-container>
		<h1 v-if="invalFlg">停止中</h1>
	</v-container>
</template>

<script>

export default {
	name: 'Invalid',
	data() {
		return {
			memberName: '',
			workPlace:'',
			mail1:'',
			mail2:'',
			invalFlg: false,
		}
	},
	mounted: function () {
		const memberId = this.$route.query.id? Number(this.$route.query.id) : 99999;
		this.axios.post('get-memberinfo', { memberId: memberId })
		.then((res) => {
			console.log(res);
			if(res.data.memberInfo.status == 1){
				const memberInfo = res.data.memberInfo;
				const params = {
					memberName : memberInfo.member_name,
					workPlace : memberInfo.workplace,
					mail1 : memberInfo.mail1,
					mail2 : memberInfo.mail2,
					item : memberInfo.item,
					userSubject : memberInfo.user_subject,
					template : memberInfo.mail_template,
				}
				this.$router.push({ name: 'Entry', params: params });
			}else{
				this.invalFlg = true;
			}
		})
	}
}
</script>