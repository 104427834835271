import Vue from 'vue'
import VueRouter from 'vue-router'
import Entry from '../views/Entry.vue'
import Invalid from '../views/Invalid.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Invalid',
    component: Invalid 
  },
  {
    path: '/entry',
    name: 'Entry',
    component: Entry 
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
