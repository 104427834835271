<template>
	<v-container>
		<div v-if="page==0">
			<v-simple-table dense class="entrytable">
				<tr>
					<th>テナント名:</th>
					<td>
						<v-text-field
							v-model="tenpoName"
							outlined
							dense
							:error="errMsg.tenpo.length>0"
							:error-messages="errMsg.tenpo"
							class="mt-6 mx-3"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>担当者名:</th>
					<td>
						<v-text-field
							v-model="tantouName"
							outlined
							dense
							:error="errMsg.tantou.length>0"
							:error-messages="errMsg.tantou"
							class="mt-6 mx-3"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>電話番号:</th>
					<td>
						<v-text-field
							v-model="tel"
							outlined
							dense
							:error="errMsg.tel.length>0"
							:error-messages="errMsg.tel"
							class="mt-6 mx-3"
							type="email"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>メールアドレス:</th>
					<td>
						<v-text-field
							v-model="mail"
							outlined
							dense
							:error="errMsg.mail.length>0"
							:error-messages="errMsg.mail"
							class="mt-6 mx-3"
							type="email"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>申込品名:</th>
					<td>
						<v-text-field
							v-model="item"
							outlined
							dense
							class="mt-6 mx-3"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>お問い合わせ内容:</th>
					<td>
						<v-text-field
							v-model="content"
							outlined
							dense
							:error="errMsg.content.length>0"
							:error-messages="errMsg.content"
							class="mt-6 mx-3"
						></v-text-field>
					</td>
				</tr>
				<tr>
					<th>写真選択:</th>
					<td>
						<div class="d-flex">
						<v-text-field
							v-model="picName"
							dense
							:error="errMsg.pic.length>0"
							:error-messages="errMsg.pic"
							class="mt-6 ml-3"
							readonly
						></v-text-field>
						<!-- <label class="photo mt-6 mr-2">
							<v-file-input @change="getFileName" type="file" capture="enviorment" style="display:none"/>
							<v-icon>mdi-camera</v-icon>
						</label> -->
						<label class="photo mt-6 mr-2">
							<v-file-input @change="getFileName" type="file" style="display:none"/>
							<v-icon>mdi-folder-multiple-image</v-icon>
						</label>
						</div>
					</td>
				</tr>
			</v-simple-table>
			<div class="my-5 text-center">
				<v-btn x-large color="success" @click="send" :disabled="sendFlg">送信</v-btn>
			</div>
		</div>
		<div v-else class="text-center">
			<p class="text-h4 py-5">送信完了しました。</p>
			<v-btn x-large color="yellow lighten-3" @click="back">戻る</v-btn>
		</div>
	</v-container>
</template>

<style scoped>
.entrytable {
	border-collapse: collapse;
}

th {
	width:140px;
	/* background-color:lightgray;
	border-top:1px solid #000;
	border-left:1px solid #000; */
	text-align: justify;
	text-align-last: justify;
	text-justify: inter-character;
}

td {
	/* border-top:1px solid #000;
	border-left:1px solid #000;
	border-right:1px solid #000; */
}

.sendbtn {
	width:150px;
	height:100px;
	padding:30px 0px !important;
	font-size:18px !important;
	margin-top:30px;
	margin-bottom:30px;
}

.photo {
	/* padding: 10px 40px; */
	/* color: #ffffff; */
	/* background-color: #384878; */
	cursor: pointer;
}
</style>

<script>
export default {
	name: 'Entry',
	data(){
		return {
			page: 0,
			memberInfo:{},
			tenpoName:'',
			tantouName:'',
			tel:'',
			mail:'',
			item:'',
			content:'',
			picName:'',
			picData:{},
			photo:'',
			collection:'',
			sendFlg:false,
			errMsg: {
				tenpo:[],
				tantou:[],
				tel:[],
				mail:[],
				content:[],
				pic:[],
			},
		}
	},
	watch: {
		photo: function (newVal) {
			this.picture = newVal;
		},
	},
	mounted: function () {
		const memberName = this.$route.params.memberName;
		const workPlace = this.$route.params.workPlace;
		const mail1 = this.$route.params.mail1;
		const mail2 = this.$route.params.mail2;
		// const item = this.$route.params.item;
		const userSubject = this.$route.params.userSubject;
		const template = this.$route.params.template;
		this.memberInfo = { memberName: memberName, workPlace: workPlace, mail1: mail1, mail2: mail2, template: template, userSubject: userSubject};
	},
	methods: {
		send: function () {
			this.sendFlg = true;

			if(this.inpCheck()){
				this.sendFlg = false; 
				return;
			}
			const postData = new FormData()
			postData.append("memberName", this.memberInfo.memberName);
			postData.append("workPlace", this.memberInfo.workPlace);
			postData.append("sendMail1", this.memberInfo.mail1);
			postData.append("sendMail2", this.memberInfo.mail2);
			postData.append("userSubject", this.memberInfo.userSubject);
			postData.append("template", this.memberInfo.template);
			postData.append("tenpoName", this.tenpoName);
			postData.append("tantouName", this.tantouName);
			postData.append("tel", this.tel);
			postData.append("mail", this.mail);
			postData.append("item", this.item);
			postData.append("content", this.content);
			postData.append("picture", this.picData);

			this.axios.post('sendmail', postData).then((res) => {
				console.log(res);
				this.sendFlg = false;
				this.page = 1;
				this.tenpoName = '';
				this.tantouName = '';
				this.tel = '';
				this.mail = '';
				this.item = '';
				this.content = '';
				this.picName = '';
				this.picData = {};
			})
		},
		back: function () {
			this.page = 0;
		},
		inpCheck: function () {
			this.clearErr();
			let errFlg = false;

			if(this.tenpoName == ''){
				this.errMsg.tenpo.push("テナント名を入力してください。");
				errFlg = true;
			}
			if(this.tantouName== ''){
				this.errMsg.tantou.push("担当者名を入力してください。");
				errFlg = true;
			}
			if(this.tel == ''){
				this.errMsg.tel.push("電話番号を入力してください。");
				errFlg = true;
			}else if(!this.tel.match(/^(0{1}\d{1,4}-{0,1}\d{1,4}-{0,1}\d{4})$/)){
				this.errMsg.tel.push("電話番号の形式が違います。");
				errFlg = true;
			}
			if(this.mail == ''){
				this.errMsg.mail.push("メールアドレスを入力してください。");
				errFlg = true;
			}else if(!this.mail.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)){
				this.errMsg.mail.push("メールアドレスの形式が違います。");
				errFlg = true;
			}
			if(this.content == ''){
				this.errMsg.content.push("お問い合わせ内容を入力してください。");
				errFlg = true;
			}
			if(this.picName == ''){
				this.errMsg.pic.push("写真を選択してください。");
				errFlg = true;
			}

			return errFlg
		},
		clearErr: function () {
			this.errMsg.tenpo = [];	
			this.errMsg.tantou = [];	
			this.errMsg.tel = [];	
			this.errMsg.mail = [];	
			this.errMsg.content = [];	
			this.errMsg.pic = [];	
		},
		getFileName: function (e) {
			console.log(e)
			this.picName = e.name;
			this.picData = e;
		}
	}
}
</script>